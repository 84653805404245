import React from 'react';
import './Glass.css';

export default function Glass({
  imgUrl = '',
  imgAlt = '',
  imgRight = false,
  children
}) {
  // Styles for component to use
  const imgBaseStyles = 'pointer-events-none w-64 h-64 rounded-full border-8 border-white-400 mb-8';
  const imgStylesOnXlDevice = 'md:mb-0';
  const imgStyles = [imgBaseStyles, imgStylesOnXlDevice].join(' ');

  return (
    <div className="glass w-full mb-8 p-8 xl:mb-16 xl:p-16">
      <div className="md:flex w-full">
        {
          imgUrl && (
            <img
              src={imgUrl}
              alt={imgAlt}
              className={`${imgStyles} md:mr-16 ${imgRight && 'block md:hidden'}`}
            />
          )
        }
        <div>
          {children}
        </div>
        {
          imgUrl && imgRight && (
            <img
              src={imgUrl}
              alt={imgAlt}
              className={`${imgStyles} hidden md:block md:ml-16`}
            />
          )
        }
      </div>
    </div>
  );
}
