import React from 'react';
import About from './About';
import Contact from './Contact';
import Experience from './Experience';
import Welcome from './Welcome';

export default function Home() {
  /**
   * NOTE:
   *
   * This component will eventually be moved to App.js once the
   * site is in a place to be turned 'live'.
   */

  return (
    <div className="gradient-bg w-screen h-screen">
      <div className="px-8 mx-auto lg:max-w-7xl">
        {/* TODO: Have a Nav here */}
        <Welcome />
        <About />
        <Experience />
        <Contact />
        {/* TODO: Have a Footer here */}
      </div>
    </div>
  );
}
