import React from 'react';
import Glass from '../layout/Glass';

export default function Welcome() {
  return (
    <div className="w-full h-screen flex items-center">
      <Glass>
        <div className="block pb-24">
          <h1 className="text-h1 text-gray-50">Nathan Gjerdingen</h1>
          <p className="text-p text-gray-50">Here&apos;s some wonderful descriptive text. Yay!</p>
        </div>
      </Glass>
    </div>
  );
}
