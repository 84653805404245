import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Construction from '../pages/Construction';
import Home from '../pages/Home';
import NoMatch from '../pages/NoMatch';

export default function Router() {
  return (
    <Routes>
      {/* Will eventually not render 'Construction' component */}
      <Route path="/" element={<Construction />} />
      {/* Will eventually be at root of site and not '/Home' */}
      <Route path="/Home" element={<Home />} />
      {/* Fallback route. Unsure if I want/need this? */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}
