import React from 'react';

export default function Construction() {
  return (
    <div className="text-center">
      <header
        className="bg-black min-h-screen flex flex-col items-center justify-center text-white bg-no-repeat bg-center bg-cover text-4xl"
        style={{
          backgroundImage: "url('./images/simple_shiny_bg.svg')"
        }}
      >
        <img
          src="./images/working_bg.svg"
          alt="logo"
          className="pointer-events-none"
          style={{
            height: '60vmin'
          }}
        />
        <p className="py-8">This site is currently under construction. Stay Tuned!</p>
        <p>
          Image from:
          {' '}
          <a
            href="https://iradesign.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#61dafb'
            }}
          >
            Illustrations by IRA Design
          </a>
        </p>
      </header>
    </div>
  );
}
