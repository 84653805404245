import React from 'react';
import Glass from '../layout/Glass';

export default function Contact() {
  return (
    <>
      <h2 className="text-h2 text-gray-50">Contact</h2>
      <Glass
        imgUrl="./images/me.jpg"
        imgAlt="Nathan Gjerdingen"
        imgLeft
      >
        <p className="text-p text-gray-50 mb-4">
          {/* eslint-disable-next-line max-len */}
          Eget felis eget nunc lobortis mattis aliquam faucibus purus. Turpis tincidunt id aliquet risus feugiat in ante. Eget duis at tellus at urna. Lorem dolor sed viverra ipsum. Justo nec ultrices dui sapien eget mi. A lacus vestibulum sed arcu non odio euismod lacinia. In aliquam sem fringilla ut morbi. Donec massa sapien faucibus et. Tortor at auctor urna nunc id cursus metus. Dignissim suspendisse in est ante in nibh. Id ornare arcu odio ut sem nulla pharetra diam sit. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem. Lectus magna fringilla urna porttitor rhoncus dolor. Dis parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Pulvinar mattis nunc sed blandit libero.
        </p>
        <p className="text-p text-gray-50">
          {/* eslint-disable-next-line max-len */}
          Nec sagittis aliquam malesuada bibendum arcu vitae. Pharetra magna ac placerat vestibulum lectus. Volutpat lacus laoreet non curabitur gravida arcu ac tortor. Euismod quis viverra nibh cras pulvinar mattis. Ultrices sagittis orci a scelerisque. Quis viverra nibh cras pulvinar. Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum. Non arcu risus quis varius quam quisque. Tortor pretium viverra suspendisse potenti nullam. Neque laoreet suspendisse interdum consectetur libero id faucibus. Accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Amet est placerat in egestas. Eget dolor morbi non arcu risus. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Nunc aliquet bibendum enim facilisis gravida neque convallis. Ut consequat semper viverra nam libero. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut.
        </p>
      </Glass>
    </>
  );
}
