import React from 'react';
import Glass from '../layout/Glass';

export default function Experience() {
  return (
    <>
      <h2 className="text-h2 text-gray-50">Experience</h2>
      <Glass
        imgUrl="./images/me.jpg"
        imgAlt="Nathan Gjerdingen"
        imgRight
      >
        <p className="text-p text-gray-50">
          {/* eslint-disable-next-line max-len */}
          Vel facilisis volutpat est velit. Tellus cras adipiscing enim eu turpis egestas pretium aenean pharetra. Fusce ut placerat orci nulla. Aliquam id diam maecenas ultricies mi eget mauris. Tristique nulla aliquet enim tortor at auctor. Neque viverra justo nec ultrices. Odio morbi quis commodo odio aenean sed. Elementum nibh tellus molestie nunc non blandit massa enim. Praesent tristique magna sit amet purus gravida quis blandit. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Vulputate mi sit amet mauris commodo quis imperdiet. Vitae congue eu consequat ac felis.
        </p>
      </Glass>
    </>
  );
}
