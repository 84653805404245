import React from 'react';

export default function NoMatch() {
  return (
    <div>
      <h1 className="text-3xl font-bold underline">
        404, Page not found!
      </h1>
    </div>
  );
}
