import React from 'react';
import Glass from '../layout/Glass';

export default function About() {
  return (
    <>
      <h2 className="text-h2 text-gray-50">About</h2>
      <Glass
        imgUrl="./images/me.jpg"
        imgAlt="Nathan Gjerdingen"
        imgLeft
      >
        <h3 className="text-h3 text-gray-50 font-bold">This is some h3 text</h3>
        <p className="text-p text-gray-50">
          {/* eslint-disable-next-line max-len */}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla malesuada pellentesque elit eget gravida. Donec ac odio tempor orci dapibus. Sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Ut eu sem integer vitae justo eget magna. Commodo viverra maecenas accumsan lacus vel facilisis volutpat. Ipsum a arcu cursus vitae congue. Elementum curabitur vitae nunc sed velit dignissim sodales ut. Sit amet volutpat consequat mauris nunc congue nisi vitae. Magna sit amet purus gravida quis blandit turpis. Ornare suspendisse sed nisi lacus sed. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Non pulvinar neque laoreet suspendisse. Duis ultricies lacus sed turpis tincidunt.
        </p>
      </Glass>
      <Glass>
        <p className="text-p text-gray-50">
          {/* eslint-disable-next-line max-len */}
          Dictum at tempor commodo ullamcorper a lacus vestibulum. Morbi tristique senectus et netus. Accumsan lacus vel facilisis volutpat est velit. Mattis pellentesque id nibh tortor id aliquet lectus. Mattis nunc sed blandit libero volutpat sed. Fermentum odio eu feugiat pretium. Fames ac turpis egestas sed. Sed libero enim sed faucibus turpis in eu mi. Urna et pharetra pharetra massa massa ultricies. Eget dolor morbi non arcu risus quis. Mauris in aliquam sem fringilla ut morbi tincidunt augue. Non quam lacus suspendisse faucibus interdum posuere. Sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque eu. Lacinia at quis risus sed vulputate. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien.
        </p>
      </Glass>
    </>
  );
}
